// CSS
import "../Projects.css";

// Components
import Navigator from "../../components/Navigator";
import { InfoBoxLeftVideo, InfoBoxRightVideo } from "../../components/InfoBox";

// Images
import builderDemo from "./A_Images/builder-demo.mp4";
import webDebuggerDemo from "./A_Images/web-debugger-demo.mp4";

function Arcanima() {
  return (
    <div className="Arcanima">
      <Navigator></Navigator>
      <header className="ProjectHeader">
        <h1>Arcanima</h1>
        <a
          className="ProjectLink"
          href="https://occult-classic.itch.io/arcanima"
          target="_blank"
          rel="noreferrer"
        >
          Itch.io Page
        </a>
        <p className="ProjectDescription">
          Arcanima is a real-time singleplayer isometric puzzle game with
          narrative elements, in which the player assumes the role of a
          mysterious character trapped in a strange, symbolic, tarot-themed
          world. Divided into five distinct regions themed after disparate
          aspects of tarot — such as the minor arcana suits, cultural
          associations, and even emotional states — the player must navigate a
          variety of short grid-based levels with novel puzzle mechanics. In
          doing so, the player is enabled to discover the subtle narrative
          underscoring the game's atmosphere and environment.
        </p>
        {/* Information Section */}
        <InfoBoxLeftVideo
          header="Level Editor"
          src={builderDemo}
          alt="Level Editor"
        >
          In order to improve the workflow of the designers, a level editor tool
          was created. This tool allows the designers to build and test levels
          quickly. The editor works by creating a list of the objects and
          terrain that a designer might want to add to a level. From there, the
          designer opens a scene in Unity and builds the level they want. This
          level can then be saved and added to the level selection scene.
        </InfoBoxLeftVideo>
        <InfoBoxRightVideo
          header="Level Debugger"
          src={webDebuggerDemo}
          alt="Debugger Demo"
        >
          One issue with developing for mobile is performance. To mitigate this
          issue, a debugging tool was created. This tool logs information while
          a tester is playing and then sends it to a server when the level is
          finished. From there, the data can be loaded into a data visualization
          tool at any time by the developers. If the player denies the privacy
          policy, the data collection objects are deleted from the scene and no
          data is stored.
          <br />
          (The demo video does not contain user data.)
        </InfoBoxRightVideo>
      </header>
    </div>
  );
}

export default Arcanima;
