import "./InfoBox.css";

function InfoBoxRight({
  header = "Section Header",
  src = "",
  alt = "Image",
  imgID="",
  children,
}) {
  return (
    <div className="InfoBox">
      <img className="InfoBoxImage" src={src} alt={alt} id={imgID} />
      <div>
        <h2>{header}</h2>
        {children}
      </div>
    </div>
  );
}

function InfoBoxLeft({
  header = "Section Header",
  src = "",
  alt = "Image",
  imgID="",
  children,
}) {
  return (
    <div className="InfoBox">
      <div>
        <h2>{header}</h2>
        {children}
      </div>
      <img className="InfoBoxImage" src={src} alt={alt} id={imgID} />
    </div>
  );
}

function InfoBoxRightVideo({
  header = "Section Header",
  src = "",
  alt = "Video",
  children,
}) {
  return (
    <div className="InfoBox">
      <video className="InfoBoxVideo" src={src} alt={alt} muted controls />
      <div>
        <h2>{header}</h2>
        {children}
      </div>
    </div>
  );
}

function InfoBoxLeftVideo({
  header = "Section Header",
  src = "",
  alt = "Video",
  children,
}) {
  return (
    <div className="InfoBox">
      <div>
        <h2>{header}</h2>
        {children}
      </div>
      <video className="InfoBoxVideo" src={src} alt={alt} muted controls />
    </div>
  );
}

export { InfoBoxLeft, InfoBoxRight, InfoBoxLeftVideo, InfoBoxRightVideo };
