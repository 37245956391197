// CSS
import "../Projects.css";

// Components
import Navigator from "../../components/Navigator";
import { InfoBoxLeft, InfoBoxRightVideo } from "../../components/InfoBox";

// Images
import shipDesync from "./TSAK_Images/ship-desync.mp4";
import networkingDemoPhoton from "./TSAK_Images/networking-ui.jpg";
import gameplayFlowchart from "./TSAK_Images/gameplay-flowchart.png";

function ThisShipAintKraken() {
  return (
    <div className="ThisShipAintKraken">
      <Navigator></Navigator>
      <header className="ProjectHeader">
        <h1>This Ship Aint Kraken</h1>
        <a
          className="ProjectLink"
          href="https://industrial-paradox.itch.io/this-ship-aint-kraken"
          target="_blank"
          rel="noreferrer"
        >
          Itch.io Page
        </a>
        <p className="ProjectDescription">
          This Ship Ain't Kraken is a goofy co-op party game that puts you at
          the heart of a sea invasion of epic proportions. This Ship Ain’t
          Kraken brings the fun that comes with couch co-op party games, and
          combines it with a high stakes thrilling experience. Set sail with
          your party of four rat crew members, and travel the seven seas in
          search of a mystical beast: the Kraken. Due to the recent uproar of
          ships being lost at sea, you and your crew set out to prove that this
          is just another folk tale blown out of proportion. Loose your sails,
          load your cannons and prepare to survive the living legend that is the
          Kraken!
        </p>
        {/* Information Section */}
        <InfoBoxLeft
          header="Online Multiplayer"
          alt="Connection screen"
          src={networkingDemoPhoton}
        >
          The multiplayer was created in a peer-to-peer style, where one player
          was the host and handled the task-starting algorithm and health bars.
          An issue with the peer-to-peer model is privacy. To avoid this, we
          used the{" "}
          <a
            className="ProjectLink"
            href="https://www.photonengine.com/pun#"
            target="_blank"
            rel="noreferrer"
          >
            Photon Unity Networking
          </a>{" "}
          package. Photon runs a server that passes information between
          connected clients. This allows the “host” to connect to the server and
          set up a game. The clients then connect to the game through the Photon
          server. This means that Photon is the only service that sees the IP
          address of each client.
        </InfoBoxLeft>
        <InfoBoxRightVideo
          header="Fixing Ship Desync"
          src={shipDesync}
          alt="Demonstration of the issue with the rocking and the fix"
        >
          As players are running around the ship, the ship is rocking. This
          means that if the ship rocking is desynced across the different
          clients, the players will not show up at the correct height and will
          float above or below the deck of the ship. To fix this, an RPC was
          created to send all of the clients the time that the ship was rocking.
          This allows the rocking animation to be smooth without having the
          issue with player height being desynced.
        </InfoBoxRightVideo>
        <InfoBoxLeft
          header="Task System"
          alt="Flowchart of tasks"
          src={gameplayFlowchart}
        >
          The tasks are the events that the players need to complete to keep the
          ship from being destroyed. These events can require the player to have
          a certain item or give the player an item on interaction. Tasks are
          started by an algorithm that chooses tasks based on the number of
          players and the previous task. If there is only one player then there
          is no reason to do a Save Teammate Task. The game should also never do
          two Save Teammate Tasks in a row, that way the players have time to
          repair the ship.
        </InfoBoxLeft>
      </header>
    </div>
  );
}

export default ThisShipAintKraken;
