// CSS
import "../Projects.css";
import "./UltimateTicTacToeAI.css";

// Components
import Navigator from "../../components/Navigator";
import { InfoBoxLeftVideo, InfoBoxRightVideo } from "../../components/InfoBox";

// Images
import howToPlay from "./UTTTAI_Images/how-to-play.mp4";
import minimax from "./UTTTAI_Images/minimax.mp4";
import scoring from "./UTTTAI_Images/scoring.mp4";
import abPruning from "./UTTTAI_Images/ab-pruning.mp4";

function UltimateTicTacToeAI() {
  return (
    <div className="UltimateTicTacToeAI">
      <Navigator></Navigator>
      <header className="ProjectHeader">
        <h1>Ultimate Tic-Tac-Toe AI</h1>
        {/* IFrame allows user to play the game in the website */}
        <iframe
          className="GameViewer"
          title="Ultimate Tic-Tac-Toe"
          src="https://colinkeilbach.github.io/UltimateTicTacToe/"
        />
        {/* Information Section */}
        <InfoBoxLeftVideo
          header="How To play"
          src={howToPlay}
          alt="Image showing how Ultimate Tic-Tac-Toe is played."
        >
          Ultimate Tic-Tac-Toe is played on a set of nine Tic-Tac-Toe boards
          arranged in a Tic-Tac-Toe board. The goal is to get three wins in a
          row on the large board. When a player moves on the small board, the
          next move must be played on the corresponding board on the large
          board. If a player cannot make a move on that small board, they can
          move anywhere on the board.
        </InfoBoxLeftVideo>
        <InfoBoxRightVideo
          header="Minimax Algorithm"
          src={minimax}
          alt="Image showing how Minimax works"
        >
          Minimax works by testing a move and testing how the opponent might
          respond to it. This board state is then scored and the AI picks the
          move with the best score. Once the board has been scored the AI can
          pick the best move. The minimax algorithm runs to a depth of 3,
          meaning it checks its move, its opponent's move, and then checks its
          move again before returning the score. This allows it to try to 'plan'
          ahead to try and get a good position for itself and avoid giving the
          player the win.
        </InfoBoxRightVideo>
        <InfoBoxLeftVideo
          header="Scoring"
          src={scoring}
          alt="Demonstration of the scoring algorithm"
        >
          The scoring algorithm takes into account how many spaces have been
          captured in a row. If there are 2 Os in a row then it will gain points
          for its score but if there is an X in the remaining square then it
          will not gain any points. This allows it to discard rows that have an
          X and O in it, as neither player can win in that row.
        </InfoBoxLeftVideo>
        <InfoBoxRightVideo
          header="Alpha-Beta Pruning"
          src={abPruning}
          alt="Demonstration of the difference in moves to check"
        >
          Alpha-beta pruning works by ignoring moves that are outside of the
          current highest and lowest scored moves. This allows the AI to skip a
          large number of moves and reduces the number of moves checked to 2,059
          for the first move at a depth of 3 instead of 6561. This example of
          complexity on the first move allows for consistent numbers for the
          number of moves to check but can be completely avoided by randomly
          selecting the very first move as it has little effect on the outcome
          of the game.
        </InfoBoxRightVideo>
      </header>
    </div>
  );
}

export default UltimateTicTacToeAI;
