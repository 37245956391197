import "./App.css";
import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import UltimateTicTacToeAI from "./pages/projects/UltimateTicTacToeAI";
import ThisShipAintKraken from "./pages/projects/ThisShipAintKraken";
import Arcanima from "./pages/projects/Arcanima";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route
              path="/ultimate-tictactoe-ai"
              element={<UltimateTicTacToeAI />}
            ></Route>
            <Route
              path="/this-ship-aint-kraken"
              element={<ThisShipAintKraken />}
            ></Route>
            <Route path="/arcanima" element={<Arcanima />}></Route>
          </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
