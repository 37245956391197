import "./Navigator.css";
import React from "react";

import { NavLink } from "react-router-dom";

// contact images
import linked_in_logo from "../images/LI-In-Bug.png";
import github_logo from "../images/github-mark-white.png";
import mail_logo from "../images/mail-142.png";

const Navigator = () => {
  return (
    <div className="NavigationBar">
      <NavLink className="NavItem" to="/">
        Home
      </NavLink>
      <ContactContainer />
    </div>
  );
};

function ContactContainer() {
  return (
    <div className="NavContactContainer">
      <ContactButton
        href="https://www.linkedin.com/in/colin-keilbach"
        src={linked_in_logo}
        alt="Linked-In"
      ></ContactButton>
      <ContactButton
        href="https://github.com/ColinKeilbach/"
        src={github_logo}
        alt="GitHub"
      ></ContactButton>
      <ContactButton
        href="mailto:hello@colinkeilbach.com"
        src={mail_logo}
        alt="GitHub"
      ></ContactButton>
    </div>
  );
}

function ContactButton({ href, src, alt }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <button className="NavContactButton">
        <img className="NavContactLogo" src={src} alt={alt} />
      </button>
    </a>
  );
}

export default Navigator;
