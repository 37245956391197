import Node from "../components/Node";
import "./Home.css";

import { InfoBoxRight } from "./../components/InfoBox";

// project images
import utttai_logo from "./projects/UTTTAI_Images/UlitmateTicTacToeLogo.png";
import tsak_logo from "./projects/TSAK_Images/ThisShipAintKrakenLogo.png";
import arcanima_logo from "./projects/A_Images/ArcanimaLogo.png";

// contact images
import linked_in_logo from "../images/LI-In-Bug.png";
import github_logo from "../images/github-mark-white.png";
import mail_logo from "../images/mail-142.png";

// profile image
import colin_keilbach from "../images/colin-keilbach.png";

function Home() {
  return (
    <div className="Home">
      <header className="HomeHeader">
        <ProfileHeader></ProfileHeader>
      </header>
      <body>
        <LineBreak />
        <h1>Projects</h1>
        <NodeContainer />
        <LineBreak />
        <h1>Contacts</h1>
        <ContactContainer />
      </body>
    </div>
  );
}

function ProfileHeader() {
  return (
    <div className="ProfileHeader">
      <InfoBoxRight
        header="Hello, I'm Colin"
        src={colin_keilbach}
        alt="Picture"
        imgID="ProfileImage"
      >
        A game programer who focuses on game AI and networking. I love the
        challenge of solving a puzzle and learning new things.
      </InfoBoxRight>
    </div>
  );
}

function LineBreak() {
  return <div className="Linebreak"></div>;
}

function NodeContainer() {
  return (
    <div className="NodeContainer">
      <Node
        title="Ultimate TicTacToe AI"
        date="August 2022 - March 2023"
        src={utttai_logo}
        href="/ultimate-tictactoe-ai"
      >
        <ul>
          <li>Created a minimax algorithm to play Ultimate TicTacToe.</li>
          <li>Used alpha-beta pruning to speed up calculation.</li>
          <li>Networked using Node.js and WebSockets.</li>
        </ul>
      </Node>
      <Node
        title="Arcanima"
        date="August 2022 - May 2023"
        src={arcanima_logo}
        href="/arcanima"
      >
        <ul>
          <li>Created a mobile game with a team.</li>
          <li>Worked as a systems and lead programmer.</li>
          <li>Created systems for levels, interactions, and level loading.</li>
          <li>Created debugging tool for testing.</li>
        </ul>
      </Node>
      <Node
        title="This Ship Aint Kraken"
        date="January 2022 - May 2022"
        src={tsak_logo}
        href="/this-ship-aint-kraken"
      >
        <ul>
          <li>Created a networked party game with a team.</li>
          <li>Worked as networking and lead programmer.</li>
          <li>
            Created and networked systems for tasks for the players to complete.
          </li>
        </ul>
      </Node>
      {/* <Node
    title="Behavior Tree Editor"
    date="July 2022 - August 2022"
    href="/behavior-tree-editor"
  >
    <ul>
      <li>Created a behavior tree editor.</li>
    </ul>
  </Node>
  <Node
    title="Pong in Assembly"
    date="December 2021"
    href="/pong-in-assembly"
  >
    <ul>
      <li>Used Assembly to create the classic game Pong.</li>
    </ul>
  </Node> */}
    </div>
  );
}

function ContactContainer() {
  return (
    <div className="ContactContainer">
      <ContactButton
        href="https://www.linkedin.com/in/colin-keilbach"
        src={linked_in_logo}
        alt="Linked-In"
      ></ContactButton>
      <ContactButton
        href="https://github.com/ColinKeilbach/"
        src={github_logo}
        alt="GitHub"
      ></ContactButton>
      <ContactButton
        href="mailto:hello@colinkeilbach.com"
        src={mail_logo}
        alt="GitHub"
      ></ContactButton>
    </div>
  );
}

function ContactButton({ href, src, alt }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <button className="ContactButton">
        <img className="ContactLogo" src={src} alt={alt} />
      </button>
    </a>
  );
}

export default Home;
