import "./Node.css";
import React, { useRef, useEffect } from "react";

// Node Element
function Node({
  title = "Project Title",
  date = "May 2001 - May 2022",
  src = "",
  alt = "Project Image",
  href = "/",
  background = "",
  id,
  children,
}) {
  const ref = useRef(null);
  const imgRef = useRef(null);
  let expanded = false;

  useEffect(() => {
    ref.current.style.setProperty("--background-color", background);

    document.addEventListener("scroll", () => {
      if (!expanded && isInViewport(ref.current)) {
        expanded = true;
        ref.current.classList.toggle("start");
        ref.current.classList.toggle("on");
        imgRef.current.classList.toggle("off");
        imgRef.current.classList.toggle("on");
      }
    });
  }, []);

  return (
    <div>
      <div className="Node start" ref={ref} id={id}>
        <div className="NodeHeader">
          <img className="NodeImage off" src={src} alt={alt} ref={imgRef} />
          <div className="NodeTitleDate">
            <h1 className="NodeTitle">{title}</h1>
            <p className="NodeDate">{date}</p>
          </div>
        </div>
        <p className="NodeDescription">{children}</p>
        <a
          className="NodeLink"
          href={href}
          target="_self"
          rel="noopener noreferrer"
        >
          See more information →
        </a>
      </div>
    </div>
  );
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default Node;
